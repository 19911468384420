<template>
  <el-dialog
    v-el-drag-dialog
    title="选择参考尺码"
    width="600px"
    custom-class="dialog-header-border"
    :append-to-body="true"
    :visible.sync="dialogVisible"
    @closed="closed"
  >
    <el-radio-group v-model="curVal" size="small" class="radio-group gutter-8" @change="onchange">
      <el-radio
        v-for="item in list"
        :key="item"
        :label="item"
        border
        class="radio-item"
      >
        参考尺码 {{ item }}
        <span class="text-danger">{{ item % 2 ? '已' : '未' }}{{ operationName }}</span>
      </el-radio>
    </el-radio-group>
  </el-dialog>
</template>

<script>
export default {
  props: {
  
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      curVal: '',
      operationName: '校验'
    }
  },
  methods: {
    onchange(val) {
      this.dialogVisible = false
      this.valueResolve(val)
    },
    open() {
      this.dialogVisible = true
    },
    closed() {
      // this.curVal = ''
      // this.dialogVisible = false
      this.handleDestroy()
    }
  }
}
</script>

<style lang="scss"></style>
